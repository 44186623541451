<template>
  <div style="min-width: 350px">
    <header>
      <!-- <div @click="goOff()" class="fanhui center">
        <a href="javascript:void(0);"></a>
      </div> -->
      <div class="title">
        <span>登录</span>
        <p>
          还没有账号？
          <router-link :to="{ path: 'zhucezh', query: { setid: 1231231 } }"
            >去注册
          </router-link>
        </p>
      </div>
      <!-- 手机号码 验证码 登录键 -->
      <div class="shuru center">
        <div class="number">
          <input
            style="width: 100%"
            v-model="account"
            type="text"
            placeholder="输入手机号"
          />
        </div>
        <!-- <div style="margin-top: 20px;" class="number">
                 <input v-model="password" style="width: 280px;" class="password" type="password" placeholder="请输入手机号码">
             </div> -->
        <div class="yanzheng">
          <input
            style="width: 100%"
            v-model="password"
            type="password"
            placeholder="输入密码"
          />
        </div>
        <div class="forgetPassword">
        <div class="remember">
          <van-checkbox
            v-model="isRemember"
            icon-size="18px"
            checked-color="#1872ed"
            >记住密码</van-checkbox
          >
        </div>
        <div class="wjmm" @click="forgetPassword">忘记密码？</div>
        </div>
      </div>
    </header>
    <div class="denglu center">
      <button @click="login()">登录</button>
      <div class="sjdl" @click="toCodeLoginPage">验证码登录</div>
    </div>    
    <!-- 已阅读协议 -->
    <div class="xieyi">
      <van-checkbox
        v-model="agreement"
        icon-size="18px"
        checked-color="#1872ed"
      ></van-checkbox>
      <span>
        已阅读并同意<a
          href="javascript:void(0);"
          @click="$router.push({ path: '/privacy' })"
          >《服务协议》</a
        >和<a
          href="javascript:void(0);"
          @click="$router.push({ path: '/agreement' })"
          >《隐私协议》</a
        >
      </span>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
import { mapGetters } from "vuex";
import { Base64 } from "js-base64";
import Cookies from "js-cookie";
Vue.use(Toast);

export default {
  computed: {
    ...mapGetters({
      org_id: "user/getOrgId",
    }),
  },
  data() {
    return {
      account: "",
      password: "",
      isRemember: false,
      agreement: true,
    };
  },
  created() {
    this.queryLocalForm();
  },
  methods: {
    forgetPassword(){
      this.$router.push({path:'zhuce',query:{setid:1231231}})
    },
    checkOrgIdIsChunzai(callBack) {
      if (this.org_id) {
        callBack && callBack(this.org_id);
      } else {
        this.$api
          .post("/common/index/getOrgInfo", {
            domain: window.location.host,
            // domain: 'wx-adia.shkt.online'
            // domain: 'wx-adia.shanhuketang.com'
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.$store.commit("user/setOrgId", res.data.data.uid);
              localStorage.setItem("tengface_org_id", res.data.data.uid);
              callBack && callBack(res.data.data.uid);
            }
          })
          .catch(() => {
            callBack && callBack();
          });
      }
    },
    queryLocalForm() {
      const localForm = Cookies.get("LOCAL_KEY");
      console.log("localForm", localForm);
      if (localForm) {
        this.isRemember = true;
        try {
          const { username, password } = JSON.parse(localForm);
          this.account = Base64.decode(username);
          console.log("[<-- this.acaout -->]", this.account);
          this.password = Base64.decode(password);
          console.log("[<-- this.password -->]", this.password);
        } catch (error) {
          console.error("本地数据解析失败~", error);
        }
      } else {
        this.isRemember = false;
      }
    },
    // 登录
    login() {
      if (!this.agreement) {
        return Toast.fail("请先阅读并同意协议！");
      }
      if (this.account === "" || this.password === "") {
        return Toast.fail("账号、密码不能为空");
      }

      this.checkOrgIdIsChunzai((org_id) => {
        const data = {
          account: this.account,
          password: this.password,
          org_id: org_id,
        };
        this.$api.post("/user/index/login", data).then(async (res) => {
          if (res.data.code === 200) {
            // 检测是否需要记住密码
            if (this.isRemember) {
              const localForm = {
                username: Base64.encode(this.account),
                password: Base64.encode(this.password),
              };
              Cookies.set("LOCAL_KEY", JSON.stringify(localForm));
            } else {
              Cookies.remove("LOCAL_KEY");
            }
            localStorage.setItem("token", res.data.data.token);
            this.$store.commit("user/setFansId", res.data.data.fansId);
            localStorage.setItem("tengface_fans_id", res.data.data.fansId);
            this.$store.commit("user/setIScheckToken", true);
            Toast.success("登录成功");
            // this.$router.push({
            //   path: '/'
            // })
            this.toPage();
            this.getUserInfo()
          } else {
            Toast.fail("账号密码错误或为空");
          }
        });
      });
    },
     getUserInfo() {
      this.$api.post('/user/index/getUserInfo').then((res) => {
        if (res.data.code === 200) {
          console.log('[<-- res.data.data.stu_note -->]',res.data.data.stu_note);
          this.$store.commit('user/setRemark',res.data.data.stu_note)
        } 
      })
    },
    toPage() {
      const { redirectUrl } = this.$route.query;
      if (redirectUrl) {
        // window.location.href = redirectUrl
        window.location.replace(redirectUrl);
      } else {
        this.$router.replace({
          path: "/",
        });
        window.location.reload()
      }
    },
    toCodeLoginPage() {
      const { redirectUrl } = this.$route.query;
      this.$router.replace({ path: "/denglu", query: { redirectUrl } });
    },
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
body {
  width: 100%;
  height: 768px;
  font-family: PingFangSC, PingFangSC-Semibold;
}
.center {
  margin: 0 auto;
}
input {
  border: 0;
  font-size: 14px;
  color: #303133;
  font-weight: 400;
}
a {
  text-decoration: none;
}
/* 头部 */
input:-webkit-autofill {
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
}
input {
  caret-color: #1872ed;
}
.sjdl {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #1872ed;
  text-align: center;
}
.wjmm {
  font-size: 12px;
  float: right;
  color: #323233;
  margin-top: 10px;
}
header {
  height: 250px;
  background-image: linear-gradient(160deg, #e0edff 10%, #fff 70%);
  background-size: 100% 100%;
}
header .fanhui {
  height: 44px;
}
header .fanhui a {
  display: block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/ico_fanhui.png) no-repeat center;
  position: relative;
  top: 30px;
  left: 16px;
}
header .title {
  padding-top: 35px;
  margin-left: 30px;
}
header .title span {
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: #303133;
}
header .title p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #888888;
}
header .title p a {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #1872ed;
}
.shuru {
  width: 84%;
  margin-top: 24px;
}
.shuru .number {
  overflow: hidden;
  height: 44px;
  background: #f6f6f6;
  border-radius: 22px;
}
.shuru .number span {
  display: block;
  float: left;
  margin-left: 6px;
  width: 46px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #101010;
}
.password input {
  float: left;
  height: 44px;
  background: #f6f6f6 !important;
  width: 380px !important;
}
.shuru input {
  float: left;
  height: 44px;
  background: #f6f6f6;
  padding: 0 20px;
  border-radius: 25px;
}

.shuru .yanzheng {
  overflow: hidden;
  margin-top: 20px;
  /* width: 315px; */
  height: 44px;
  background: #f6f6f6;
  border-radius: 22px;
}
.shuru .yanzheng p {
  display: block;
  float: right;
  height: 44px;
  margin-right: 20px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #1872ed;
}
.shuru .forgetPassword{
  display: flex;
  justify-content: space-between;
}
.shuru .remember {
  margin-top: 10px;
}
.denglu {
  width: 84%;
}
.denglu button {
  margin-bottom: 20px;
  display: block;
  width: 100%;
  margin-top: 25px;
  height: 44px;
  background: linear-gradient(98deg, #73aaff 0%, #0053ff 100%);
  border-radius: 22px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 44px;
  border: none;
}
.xieyi {
  position: fixed;
  left: 0;
  bottom: 40px;
  height: 20px;
  line-height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.xieyi img {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
}
.xieyi span {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #303133;
  margin-left: 10px;
}
.xieyi span a {
  color: #1872ed;
}
::v-deep .van-checkbox__icon .van-icon{
  font-size: 13px;
}
</style>
