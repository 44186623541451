
const state = {
	org_id: localStorage.getItem('tengface_org_id') || "",
  fans_id:localStorage.getItem('tengface_fans_id') || "",
  mofang_url: localStorage.getItem('tengface_mofang_url') || "",
  isCheckToken:false,
  unreadCount:localStorage.getItem('unreadCount') ||0,
  remark:localStorage.getItem('tengface_remark') || "",
}

const getters = {
  getRemark(state){
    return state.remark
  },
    getOrgId(state){
      return state.org_id
    },
    getFansId(state){
		return state.fans_id
	},
  getMoFangUrl(state) {
    return state.mofang_url
  },
  getIScheckToken(state) {
    return state.isCheckToken
  },
  getUnreadCount(state) {
    return state.unreadCount
  },
}

const actions = {
   
}

const mutations = {
  setUnreadCount(state, data) {
    state.unreadCount = data
  },
   setOrgId(state, data) {
     state.org_id = data
   },
   setFansId(state, data) {
     state.fans_id = data
   },
   setMoFangUrl(state, data) {
    state.mofang_url = data
  },
  setIScheckToken(state, data) {
    state.isCheckToken = data
  },
  setRemark(state,data){
    state.remark=data
    localStorage.setItem('tengface_remark',data)
  }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
