<template>
  <div class="info-list-box">
    <div class="info-list">
      <div class="title">{{ listObj.name }}</div>
      <div class="video-title">视频标题：{{ listObj.video_name }}</div>
      <div class="time-con">
        <span class="video-time">视频时长：{{ listObj.duration }}</span>
        <span class="accumulate-time"
          >累计视频时长：{{ listObj.view_time }}</span
        >
      </div>
      <div class="completionRate">完成率：{{ listObj.complete_rate }}%</div>
    </div>
    <van-divider v-if="itemLength > 1 && index !== itemLength - 1" />
  </div>
</template>

<script>
import Vue from 'vue';
import { Divider } from 'vant';
import { secondsToTimeFormat } from '../../utils';
Vue.use(Divider);

export default {
  props: {
    itemObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    itemLength: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      listObj: {},
    };
  },
  mounted() {
    this.listObj = JSON.parse(JSON.stringify(this.itemObj));
    this.listObj.duration = secondsToTimeFormat(this.itemObj.duration);
    this.listObj.view_time = secondsToTimeFormat(this.itemObj.view_time);
  },
};
</script>

<style scoped lang="scss">
.info-list {
  width: 100%;
  min-height: 130px;
  box-sizing: border-box;
  .title {
    font-size: 14px;
    color: #202020;
    margin-bottom: 16px;
    font-family: PingFang SC, PingFang SC-400;
  }
  .video-title,
  .completionRate {
    margin: 6px 0;
    font-size: 14px;
    color: #666;
    font-family: PingFang SC, PingFang SC-400;
    word-break: break-all;
  }
  .time-con {
    margin: 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #666;
    font-family: PingFang SC, PingFang SC-400;
  }
}
.info-list-box ::v-deep .van-divider {
  border-color: #d0d2da !important;
  color: #d0d2da !important;
  position: relative;
  z-index: 100;
}
</style>
