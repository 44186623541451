
const common = {
	getQueryString:(name)=>{
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
		var r = window.location.search.substr(1).match(reg);
		if (r != null) return unescape(r[2]);
		return null;
	},
	getQueryData:(url)=>{
		var urlAll = url;
		var tmp = urlAll.split("#")[0];
		tmp = tmp.split("?");
		if (tmp.length == 1) {
			return {};
		}
		var list = tmp[1].split("&");
		var data = {};
		for (let index = 0; index < list.length; index++) {
			var element = list[index];
			var arr = element.split('=');
			if (arr.length >= 2) {
			data[arr[0]] = element.substring(arr[0].length + 1);
			}
		}
		return data;
	},
	getUrlAddQuery:(url, data)=>{
		if(Object.values(data).length>0){
			url = url + "?";
			var isFrist = true;
			for (const key in data) {
				var value = data[key];
				if (value === '') {
					continue;
				}
				if (isFrist) {
					isFrist = false;
					url = url + key + "=" + value;
				} else {
					url = url + "&" + key + "=" + value;
				}
			}
			return url;
		}else{
			return url
		}
	},
	/**
	 * 描述：删除window.location的search中的指定参数
	**/
	deleteUrlParams(name){
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
		var r = window.location.search.substr(1).match(reg)
		if(r) {
			return window.location.href.replace(unescape(r[0]), '')
		}
		return window.location.href
	},
	checkEquipment:(type)=>{
		var u = navigator.userAgent;
		if(type == 'h5'){
			if (u.indexOf("Android") < 0 && u.indexOf("Linux") < 0 && u.indexOf("iPhone") < 0 && u.indexOf("Windows Phone") < 0 && u.indexOf("Mobile") < 0 && u.indexOf("Phone") < 0) {
				//h5 跳 pc
				var data = common.getQueryData(location.href);
				var url = window.location.protocol + "//" + window.location.host + "/PC/";
				let routerPath = location.hash
				// h5魔方网校跳转PC的网校，不能直接跳到PC首页
				if(routerPath.includes('course-home')) {
					const pcHash = routerPath.replace('course-home', 'home')
					location.href = common.getUrlAddQuery(url, data) + pcHash
				} else {
					location.href = common.getUrlAddQuery(url, data);
				}
			}
		}else{
			if (!(u.indexOf("Android") < 0 && u.indexOf("Linux") < 0 && u.indexOf("iPhone") < 0 && u.indexOf("Windows Phone") < 0)) {
				//PC 跳 h5
				var urlData = common.getQueryData(location.href);
				var baseUrl = window.location.protocol + "//" + window.location.host + "/";
				location.href = common.getUrlAddQuery(baseUrl, urlData);
			}
		}
	},
	excludeNoSuportbower:()=>{
		var u = navigator.userAgent;
		if (u.indexOf("iPhone") > 0) {
			return false
		}
		return true
	}
}

export default common