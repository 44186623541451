<template>
  <div class="wrap">
   <NavigateBar :title="'观看数据'" @handleBack="handleBack"/>
   <div class="header">
     <div class="search-box" v-if="false">
       <van-search
       v-model="keyword"
       shape="round"
       @search="handleSearchHomework"
       placeholder="请输入关键词搜索" />
     </div>
   </div>
   <div class="main" ref="main">
     <div class="homework-item" v-for="item of homeworkList" :key="item.id">
       <div class="homework-item-left">
         <div class="homework-item-title">{{item.course_name}}</div>
         <div class="homework-item-chapter">班期：{{item.class_name}}</div>
         <div class="homework-item-grade">完成率：{{item.complete_rate}}%</div>
       </div>
       <div class="homework-item-right">
         <div class="unfinish">
         </div>
         <div class="work-detail" @click="toDeatilPage(item)">
           <span>查看详情</span>
         </div>
       </div>
     </div>
   </div>
  </div>
 </template>
 
 <script>
 import NavigateBar from '../myHomework/navigateBar.vue';
 import Vue from 'vue'
 import { getHost } from '../../utils/useProxyApi'
 import { Search } from 'vant'
 Vue.use(Search)
 export default {
   props: { },
   data () {
     return {
       value:'',
       homeworkList: [],
       keyword:'',
       page: 1,
       pageSize: '',
       insertmoblie: 0
      }
   },
   components:{
     NavigateBar
   },
   mounted(){
     window.addEventListener('scroll',this.handleScroll,true)
   },
   beforeDestroy(){
     window.removeEventListener("scroll",this.handleScroll,true)
   },
   created(){
     this.insertmoblie = this.$route.query.insertmoblie;
     if(this.$route.query.moblie){
       this.moblieType = this.$route.query.moblie
     }
     if(this.$route.query.insertmoblie == 1){
       localStorage.setItem('token',this.$route.query.token)
     }
     this.getHomeworkList()
   },
   computed: { },
   watch: { },
   methods: { 
      toDeatilPage(item){
      // 跳转查看详情页
      const query = this.$route.query
      this.$router.push({
        path: '/viewDataInfo',
        query: {
          ...query,
          classId: item.class_id
        }
      })
     },
     async getHomeworkList(){
       const data = {
         page: this.page,
         page_nums: 10
       }
      //  if(this.keyword){
      //    data.keyword = this.keyword
      //  }
      // https://test-school.tenghuiketang.com ${getHost()}
       const res = await this.$tenghuiApi.post(`${getHost()}/studentPersonalCenter/getWatchDataList`, data)     
        this.pageSize = (res.data.data.total > 10? Math.ceil(res.data.data.count/10):1)
        this.homeworkList = [...this.homeworkList, ...res.data.data.list]
     },
     handleScroll(){
       let dom = window.document.documentElement || window.document.body
       //文档内容实际高度（包括超出视窗的溢出部分）
       let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
       //滚动条滚动距离
       let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
       //窗口可视范围高度
       let clientHeight = dom.innerHeight || Math.min(dom.clientHeight,dom.clientHeight);
       if(clientHeight + scrollTop+100 >= scrollHeight){
         if(this.page >= this.pageSize ) return
         this.page++
         this.getHomeworkList()
       }
      },
      handleSearchHomework(){
        this.homeworkList = []
        this.page = 1    
        this.getHomeworkList()
      },

      handleBack(){
        this.$router.go(-1)
      }
   },
 }
 </script>
 
 <style lang="scss" scoped>
 .wrap{
   width: 100%;
   height: 100%;
   background: #f6f6f6;
 }
 
   .header{
     box-sizing: border-box;
     padding: 0 10px;
     padding-bottom: 7px;
     background: #ffffff;
   }
   .search-box{
     ::v-deep .van-search{
       padding: 0;
       border-radius: 18px;
     }
     ::v-deep .van-search__content{
       background-color: #f6f6f6;
     }
     ::v-deep .van-icon-search{
       font-weight: 700;
     }
     
     ::v-deep input[type=search]::placeholder {
             color: #7E8180;
     }
   }
   .main{
     box-sizing: border-box;
     padding: 10px;
     background: #f6f6f6;
     .homework-item{
       background: #ffffff;
       box-sizing: border-box;
       padding: 12px;
       border-radius: 10px;
       display: flex;
       margin-bottom: 10px;
       .homework-item-right{
         margin-left: auto;
         display: flex;
         flex-direction: column;
         .unfinish{
           font-size: 12px;
           font-weight: 400;
           color: #7e8180;
           line-height: 20px;
           .num{
             color: #FF4229;
           }
         }
         .work-detail{
           width: 64px;
           height: 24px;
           background: linear-gradient(133deg,#73aaff 3%, #0053ff 91%);
           border-radius: 16px;
           font-size: 12px;
           font-weight: 400;
           color: #fefffe;
           line-height: 24px;
           text-align: center;
           margin-top: auto;
           position: relative;
           .red-dot {
             position: absolute;
             display: inline-block;
             width: 8px;
             height: 8px;
             background-color: red;
             border-radius: 50%; 
             top: -2px;
             right: 1px;
           }
         }
       }
       .homework-item-title{
         font-size: 16px;
         font-weight: 600;
         color: #202020;
         line-height: 22px;
         font-size: 16px;
         max-width: 256px;
         overflow:hidden;
         text-overflow:ellipsis;
         white-space:nowrap
       }
       .homework-item-chapter{
         margin: 10px 0 4px 0;
         font-size: 14px;
         max-width: 200px;
         overflow:hidden;
         text-overflow:ellipsis;
         white-space:nowrap;
         color: #999
       }
       .homework-item-grade{
         font-size: 14px;
         color: #7e8180;
         max-width: 200px;
         overflow:hidden;
         text-overflow:ellipsis;
         white-space:nowrap;
       }
     }
   }
   ::v-deep .van-icon-search:before{
     content: '';
     background: url('../../assets/images/serch_button.png') no-repeat;
     background-size: contain;
     width: 16px;
     height: 16px;
 }
 ::v-deep .van-icon-search{
     top:2px;
 }
 </style>
 