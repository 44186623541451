<!-- 课程详情-视频文稿组件 -->
<template>
    <div style="height: calc(100% - 39px);">
        <div class="zanwu" v-show="subtitles.length==0">
            <img src="@/assets/subtitle/none.png" class="zanwu_img">
            <div class="text" v-if="!isSelectVideo">暂无文稿解说，请选择视频播放</div>
            <div class="text" v-else>该视频暂无解说，请咨询老师</div>
        </div>
        <div class='video-draft course-chapter' v-show="subtitles.length>0">
                <div v-show="isKeyword" class="noKeyword">抱歉，暂未找到匹配的关键词</div>
        <!-- 搜索框 -->
        <div class="search-box">
            <!-- <input v-model="text" class="search-input" @keyup.enter.native="searchText" placeholder="请输入搜索关键词" prefix-icon="el-icon-search" /> -->
            <van-search v-model="text" shape="round" class="search-input" placeholder="请输入搜索关键词" @search="searchText" @clear="searchText"/>
            <div class="search-result" v-if="searchVal">
                <!-- <i class="el-icon-arrow-left" @click="prevResult"></i> -->
                <van-icon name="arrow-left" @click="prevResult"/>
                <div>
                    <span class="current-result">{{ currentResultIndex }}</span>
                    /
                    <span class="total-result">{{ matchContents.length }}</span>
                </div>
                <van-icon name="arrow" @click="nextResult" class="el-icon-error"/>
            </div>
        </div>
        <p class="tips">[ 注 : 以下文稿由AI生成 ]</p>
        <!-- 字幕内容 -->
        <ul class="subtitles">
            <li class="subtitles-item" v-for="(group, index) in subtitlesGroups" :key="index">
                <p class="time">{{ groupStartTime(group) }}</p>
                <p class="docs-content">
                    <template v-for="item in group">
                        <!-- <span class="docs-content-text" :class="{ current: isCurrent(item), 'has-searched': isSearched(item) }" 
                            @click="setPlayerTime(item)">
                            {{ item.content }}
                        </span> -->
                        <span class="docs-content-text" :class="{ current: isCurrent(item) }" 
                        @click="setPlayerTime(item)" v-html="item.searchContent || item.content">
                            </span>
                    </template>
                </p>
            </li>
            <!-- <div class="bottom">已经到底啦~</div> -->
        </ul>
        
        <div class="back-wrapper" v-if="showPlayingPositionBtn" @click="toPlayingPosition">
            <img src="@/assets/subtitle/play.png" width="16" height="16">
            <span style="margin-left: 6px;">回到播放处</span>
        </div>
    </div>
        <!-- <div class="mask" v-show="isKeyword">
            <div class="noKeyword">抱歉，暂未找到匹配的关键词</div>
        </div> -->
    </div>
</template>
<script>
export default {
    props: {
        subtitles: {
            type: Array,
            default: () => []
        },
        isSelectVideo:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            isKeyword:false,
            text: '',
            searchVal: '',
            currentTime: '',
            currentResultIndex: 0,
            resultTotal: 0,
            matchContents: [],
            autoScrollTimer: null,
            showPlayingPositionBtn: false,
            contentElm: null

        }
    },
    watch: {
        currentTime() {
            // 如果是在搜索情况下，则需要关闭自动滚动，否则会出现字幕抢占滚动的情况
            if(!this.autoScrollTimer && this.matchContents.length === 0) {
                this.autoScrollTimer = setInterval(() => {
                    if(this.showPlayingPositionBtn) {
                        return
                    }
                    this.autoScrollCurrentSubTitle()
                }, 1000)
            }
        }
    },
    computed: {
        groupStartTime() {
            return (group) => {
                const startTime = group[0].start_time_offset
                const second = Math.floor(startTime % 60)
                const minute = Math.floor(startTime / 60)
                return `${ minute > 9 ? minute : `0${minute}` }:${ second > 9 ? second : `0${second}` }`
            }   
        },
        isCurrent() {
            return ({ start_time_offset, end_time_offset }) => {
                return start_time_offset <= this.currentTime && end_time_offset >= this.currentTime
            }
        },
        isSearched() {
            return (item) => {
                if(this.searchVal) {
                    return item.content.includes(this.searchVal)
                }
                return false
            }
        },
        subtitlesGroups() {
            return this.subtitles.reduce((groups, item) => {
                const group = Math.floor(item.start_time_offset / 60)
                groups[group] = groups[group] || []
                groups[group].push(item)
                return groups
            }, {})
        }
    },
    mounted(){
        this.initEvent()
    },
    methods: {
        // changeSearchText(){
        //     this.currentResultIndex = 0
        // },
        initEvent() {
            document.querySelector('.video-draft').querySelector('.subtitles').addEventListener('touchstart', this.showBtn)
            if(this.subtitles === 0) {
                return
            }
            this.$nextTick(() => {
                this.contentElm = document.querySelector('.video-draft').querySelector('ul.subtitles')
                this.contentElm.addEventListener('touchstart', this.showBtn)
            })

        },
        showBtn() {
            this.showPlayingPositionBtn = true
        },
        /**
         * 描述：设置当前播放器实时播放的时间
         * @param   : val {number} 播放时间
        **/
        setCurrentTime(val) {
            this.currentTime = val
        },
        /**
         * 描述：点击字幕设置播放器时间为当前字幕的开始时间
         * @param   : start_time_offset {number} 字幕开始时间
        **/
        setPlayerTime({ start_time_offset }) {
            this.$emit('updatePlayerTime', start_time_offset)
        },
        /**
         * 描述：清除搜索框和结果
        **/
        clearSearch() {
            this.text = ''
            this.searchVal = ''
            this.matchContents = []
            for(const attr in this.subtitlesGroups) {
                const group = this.subtitlesGroups[attr]
                group.forEach(subtitle => {
                    // 去除html标签
                    subtitle.searchContent = ''
                    subtitle.content = subtitle.content.replace(/<[^>]*>/g, '')
                })
            }

        },
        searchText() {
            if(!this.text) {
                this.clearSearch()
                return
            }
            this.matchContents = []
            this.searchVal = this.text
            this.filterContent()
        },
        addMatchContents(val) {
            this.matchContents = val
        },
        filterContent() {
            for(const attr in this.subtitlesGroups) {
                const group = this.subtitlesGroups[attr]
                // group.forEach(({ content }) => {
                //     if(content.includes(this.searchVal)) {
                //         this.matchContents.push(content)
                //     }
                // })
                group.forEach(subtitle => {
                    if(subtitle.content.includes(this.searchVal)) {
                        subtitle.searchContent = subtitle.content.replace(
                            new RegExp(this.searchVal, 'g'), '<em class="has-searched" style="color: #1872ED;font-style: normal;">$&</em>'
                        )
                        this.matchContents.push(subtitle.content)
                    } else {
                        subtitle.searchContent = ''
                    }

                })

            }
            if(this.matchContents.length === 0) {
                this.currentResultIndex = 0
                // this.$message.warning('抱歉，暂未找到匹配的关键词')
                this.isKeyword=true
                setTimeout(() => {
                    this.isKeyword=false
                }, 2000);
            } else {
                this.currentResultIndex = 1
                this.$nextTick(() => {
                    this.scrollToSearchResult();
                });
            }
        },
        prevResult() {
            if(this.currentResultIndex === 1) {
                this.currentResultIndex = this.matchContents.length
            } else {
                this.currentResultIndex--
            }
            this.scrollToSearchResult()
        },
        nextResult() {
            if(this.currentResultIndex === this.matchContents.length) {
                this.currentResultIndex = 1
            } else {
                this.currentResultIndex++
            }
            this.scrollToSearchResult()
        },
        /**
         * 描述：滚动当前index字幕，让其出现在可视区域
        **/
        scrollToSearchResult() {
            this.clearTimer()
            const index = this.currentResultIndex - 1
            this.$nextTick(() => {
                const el = document.querySelector('ul.subtitles').querySelectorAll(`.has-searched`)[index]
                if(el) {
                    this.scrollToPosition(el)
                    this.showPlayingPositionBtn = true
                }
            })

        },
         /**
         * 描述：判断当前正在显示的字幕是否在可视区域中
         * @return  : {boolean} true: 在可视区域内   false: 不在可视区域内
        **/
        checkCurrentInView() {
            const ulElm = document.querySelector('ul.subtitles')
            if(ulElm) {
                const subtitleElm = ulElm.querySelector(`.current`)
                if(subtitleElm) {
                    const currentSubtitleElmRect = subtitleElm.getBoundingClientRect()
                    const subtitlesRect = ulElm.getBoundingClientRect()
                    const isInView = (currentSubtitleElmRect.top > subtitlesRect.top && currentSubtitleElmRect.bottom < subtitlesRect.bottom)
                    return { 
                        isInView,
                        currentElm: subtitleElm
                    }
                } else {
                    return {}
                }
            } else {
                return {}
            }
        },
        /**
         * 描述：自动滚动当前字幕到可视区域
        **/
        autoScrollCurrentSubTitle() {
            const { isInView, currentElm } = this.checkCurrentInView()
            // if(!isInView && currentElm) {
            //     currentElm.scrollIntoView({
            //         behavior: 'smooth',
            //         block: 'center'
            //     })
            // }
            this.scrollToPosition(currentElm)
        },
        /**
         * 描述：当前元素滚动到视口中间
         * @param   : element {HTMLElement} 需要滚动的元素
        **/
        scrollToPosition(element) {
            if(element) {
                const height = element.offsetTop - this.contentElm.getBoundingClientRect().height / 2
                this.contentElm.scrollTo({
                    top: height,
                    behavior: 'smooth'
                })
            }
        },
        clearTimer() {
            if(this.autoScrollTimer) {
                clearInterval(this.autoScrollTimer)
                this.autoScrollTimer = null
            }
        },
        /**
         * 描述：定位到当前播放的字幕位置（当前播放时间不一定会有字幕）
        **/
        toPlayingPosition() {
            let subtitleElm = document.querySelector('ul.subtitles').querySelector(`.current`)
            // 有字幕时
            if(!subtitleElm) {
                // 没有字幕时，定位到下一个字幕
                let minuteIndex = 0
                const index = Math.floor(this.currentTime / 60)
                const group = this.subtitlesGroups[index]
                for(const attr in this.subtitlesGroups) {
                    minuteIndex++
                    if(index === Number(attr)) {
                        break
                    }
                }
                if(group) {
                    let jumpIndex = 0
                    for(let i = 0; i < group.length; i++) {
                        const { start_time_offset } = group[i]
                        jumpIndex++
                        if(start_time_offset > this.currentTime) {
                            break
                        }
                    }
                    subtitleElm = document.querySelector('ul.subtitles').querySelectorAll('.subtitles-item')[minuteIndex - 1].querySelectorAll('.docs-content-text')[jumpIndex - 1]
                }
            }
            // if(subtitleElm){
            //     subtitleElm.scrollIntoView({
            //     behavior: 'smooth',
            //     block: 'center'
            // })
            // }
            this.scrollToPosition(subtitleElm)
            this.showPlayingPositionBtn = false
        }
    },
    beforeDestroy() {
        document.querySelector('.video-draft').querySelector('.subtitles').removeEventListener('touchstart', this.showBtn)
        this.clearTimer()
        if(document.querySelector('.video-draft')) {
            document.querySelector('.video-draft').querySelector('.subtitles').removeEventListener('touchstart', this.showBtn)
        }

    }
}
</script>
<style lang='scss' scoped>

// .mask{
//     position: relative;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
    .noKeyword{
        position: absolute;
        top: 10%;
        left: 20%;
        width: 240px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        opacity: 0.7;
        background: #000000;
        border-radius: 40px;
        color: #ffffff;
        font-size: 16px;
    }
// }
.zanwu{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 40px;
    overflow-y: auto;
    height: 100%;
    .zanwu_img{
        width: 4.3rem;
        height: 3.7rem;
        margin-top: 1rem;
    }
    .text{
        font-size: 14px;
        color: #999999;
        margin-top: 16px;
    }
}
.video-draft{
    flex: 1;
    min-width: 375px;
    overflow-y: auto;
    color: #b7b7b7;
    padding: 0 20px;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    height: 100%;
    padding: 14px 20px !important;
    padding-bottom: 0 !important;
    .search-box {
        position: relative;
        display: flex;
        justify-content: center;
        .search-input {
            padding: 0;
            width: 100%;
            
        }
        ::v-deep .van-search__content{
            height: 36px;
            background: #f5f7fa;
            border-radius: 20px;
            align-items: center;
        }
        .search-result {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            top: 10px;
            right: 28px;
            align-items: center;
            font-size: 14px;
            color: #B7B7B7;
            .current-result {
                color: #666666;
            }
            i {
                cursor: pointer;
                &.el-icon-error {
                    margin-left: 5px;
                }
            }
        }
    }
    .tips {
        color: #666;
        font-size: 14px;
        margin: 0;
        margin-top: 10px;
    }
    .subtitles{
        margin: 0;
        padding: 0;
        height: calc(100% - 80px);
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #2B2C35;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .subtitles-item {
           // width: 330px;
            margin: 0;
            list-style: none;
            margin-top: 10px;
            .time {
                width: fit-content;
                margin: 0;
                font-size: 12px;
                background: #ededed;
                border-radius: 6px;
                padding: 4px;
                color: #202020;
            }
            .docs-content {
                margin: 0;
                margin-top: 6px;
                line-height: 18px;
            }
            .docs-content-text {
                cursor: pointer;
                font-size: 14px;
                color: #202020;
                // &:hover {
                //     color:#7090ba;
                //     border-radius: 6px;
                // }
                &.current {
                    color: #1872ED;
                }
                em {
                    font-style: normal;
                    color: #1872ED;
                }

            }
        }
    }
    .back-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 76px;
        right: 38px;
        z-index: 999;
        color: #ADB5BD;
        background: #2B2C35;
        font-size: 14px;
        padding: 6px 14px;
        border-radius: 20px;
        cursor: pointer;
    }
    .bottom{
        font-size: 14px;
        color: #999999;
        text-align: center;
        margin-top: 23px;
    }
}
.subtitles::-webkit-scrollbar-thumb {
  background-color: #DBDBDB !important; /* 设置滚动条拖动部分的颜色 */
}
::v-deep .van-icon-search:before{
    content: '';
    background: url('../assets/images/serch_button.png') no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
}
::v-deep .van-icon-search{
    top:2px;
}
</style>