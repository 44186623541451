<template>
  <div style="min-width: 350px;background: #f6f6f6;height: 667px;">
    <header>
     <div class="tit tit_fh">
            <div @click="goOff()" class="imglist" >
            <img style="width: 16px;height:16px" src="../../assets/images/ico_fanhui.png" alt="">
            </div>
        <h1>更换手机号码</h1>
        </div>
         <div class="title">
        <span>绑定新手机号码</span>
      </div>
      <!-- 手机号码 验证码 登录键 -->
      <div class="shuru center">
        <div style="background: #fff;" class="number">
          <span>+86</span>
          <input
          style="background: #fff;"
            v-model="phone"
            class="phone"
            type="text"
            placeholder="请输入手机号码"
          />
        </div>
        <!-- <div style="margin-top: 20px;" class="number">
                 <input v-model="password" style="width: 280px;" class="password" type="password" placeholder="请输入手机号码">
             </div> -->
        <div class="yanzheng" style="background: #fff;">
          <input style="background: #fff;" v-model="code" type="text" placeholder="请输入验证码" />
          <p v-show="show" @click="yanzheng()">获取验证码</p>
          <span
            v-show="!show"
            style="margin-left: 26px; line-height: 46px;font-size: 12px;color: #888888;"
            class="count"
            >重新获取 {{ count }} s</span
          >
        </div>
      </div>
    </header>
    <div class="denglu">
      <button @click="login()">修改</button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'

Vue.use(Toast)

export default {
   computed:{
		...mapGetters({
			org_id:'user/getOrgId'
		})
	},
  data () {
    return {
      phone: '',
      code: '',
      show: true,
      count: '',
      timer: null
    }
  },
  created () {
  },
  methods: {
    goOff () {
      this.$router.go(-1)
    },
    // 修改手机号
    login () {
      if (this.phone === '' || this.code === '') {
        Toast.fail('手机号、验证码不能为空')
      }
      const data = {
        area: this.area,
        phone: this.phone,
        code: this.code
      }
       this.$api
        .post(
          '/user/index/updatePhone',
          data
        )
        .then((res) => {
          if (res.data.code === 200) {
            Toast.success('修改成功')
            this.$router.push({
              path: '/ziliao'
            })
          } else {
            Toast.fail('修改失败')
          }
        })
    },
    checkOrgIdIsChunzai(callBack){
      if (this.org_id) {
        callBack && callBack(this.org_id) 
      }else{
          this.$api.post('/common/index/getOrgInfo', {
          domain: window.location.host
          // domain: 'wx-adia.shanhuketang.com'
        }).then((res)=>{
          if(res.data.code == 200){
            this.$store.commit("user/setOrgId",res.data.data.uid)
            localStorage.setItem('tengface_org_id',res.data.data.uid)
          callBack && callBack(res.data.data.uid) 	
          }
        }).catch(()=>{
          callBack && callBack() 
        });   
      }
    },
    // 获取验证码
    yanzheng () {
      this.checkOrgIdIsChunzai((org_id)=>{
        const data = {
        phone: this.phone,
        area: this.area,
        org_id: org_id
      }
       this.$api
        .post(
          '/common/index/sendSms',
          data
        )
        .then((res) => {
          if (res.data.code === 200) {
            Toast.success('获取成功')
          }
          if (res.data.code === 200) {
            const TIME_COUNT = 60
            if (!this.timer) {
              this.count = TIME_COUNT
              this.show = false
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--
                } else {
                  this.show = true
                  clearInterval(this.timer)
                  this.timer = null
                }
              }, 1000)
            }
          } else {
            Toast.fail('获取失败')
          }
        })
      })
      
    },
  }
}
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
body {
  width: 100%;
  height: 768px;
  font-family: PingFangSC, PingFangSC-Semibold;
}
.center {
  margin: 0 auto;
}
input {
  border: 0;
}
a {
  text-decoration: none;
}
/* 头部 */
.sjdl{
font-size: 14px;
font-family: PingFangSC, PingFangSC-Regular;
font-weight: 400;
margin-left: 30px;
}
.wjmm{
  font-size: 14px;
font-family: PingFangSC, PingFangSC-Regular;
font-weight: 400;
float: right;
color: #808695;
margin-right: 30px;
}
header {
  height: 260px;
  background-size: 100% 100%;
}
header .fanhui {
  height: 44px;
}
header .fanhui a {
  display: block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/ico_fanhui.png) no-repeat center;
  position: relative;
  top: 10px;
  left: 16px;
}
header .title {
  margin-top: 30px;
  margin-left: 30px;
}
header .title span {
 font-size: 14px;
font-family: PingFangSC, PingFangSC-Regular;
font-weight: 400;
text-align: right;
color: #303133;
}
header .title p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #888888;
}

.shuru {
  width: 84%;
  margin-top: 24px;
}
.shuru .number {
  height: 44px;
  background: #f6f6f6;
}
.shuru .number span {
  display: block;
  float: left;
  margin-left: 6px;
  width: 46px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #101010;
}
.password input {
  float: left;
  height: 44px;
  background: #f6f6f6;
  width: 380px !important;
}
.shuru input {
  float: left;
  height: 44px;
  background: #f6f6f6;
  padding: 0 20px;
  border-radius: 25px;
}

.shuru .yanzheng {
  margin-top: 20px;
  /* width: 315px; */
  height: 44px;
  background: #f6f6f6;
}
.shuru .yanzheng input {
  width: 30%;
  margin-left: 52px;
}
.shuru .yanzheng p {
  display: block;
  float: right;
  height: 44px;
  margin-right: 20px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #1872ed;
}
.denglu {
  width: 100%;
  display: flex;
  justify-content: center;
}
.denglu button {
  margin-bottom: 20px;
  display: block;
  width: 320px;
  margin-top: 25px;
  height: 44px;
  background: linear-gradient(98deg,#73aaff 0%, #0053ff 100%);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 44px;
  border: none;
}

.tit {
  width: 100%;
  height: 1.17rem;
  background-color: #ffffff;
}

.tit h1 {
  float: left;
  font-size: 0.45rem;
  font-weight: 600;
  color: #101010;
  margin-left: 2.64rem;
  margin-top: 0.24rem;
}
.imglist {
  float: left;
  margin-left: 0.43rem;
  margin-top: 0.4rem;
}
</style>
