<template>
  <div class="view-info">
    <!-- 头部 -->
    <NavigateBar title="查看详情" @handleBack="$router.go(-1)"></NavigateBar>
    <!-- 主体内容 -->
    <div class="view-main" @scroll="handleScroll">
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="(item, index) in homeworkList"
          :key="index"
          :title="`第${index+1}章 ${item.name}`"
          :name="item.id">
          <InfoList
            v-for="(node, i) in item.chapter_video_list" :key="i"
            :itemObj="node"
            :itemLength="item.chapter_video_list.length"
            :index="i"
          />
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Collapse, CollapseItem } from 'vant';
import NavigateBar from '../myHomework/navigateBar'
import InfoList from './InfoList'
import { getHost } from '../../utils/useProxyApi'

Vue.use(Collapse);
Vue.use(CollapseItem);

export default {
  components: {
    NavigateBar,
    InfoList
  },
  data () {
    return {
      activeNames: ['1'],
      page: 1,
      classId: '',
      homeworkList: []
    }
  },
  created() {
    this.classId = this.$route.query.classId
    this.getHomeworkList()
  },
  computed: {
  },
  methods: {
    handleScroll() {
      let dom = window.document.documentElement || window.document.body
       //文档内容实际高度（包括超出视窗的溢出部分）
       let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
       //滚动条滚动距离
       let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
       //窗口可视范围高度
       let clientHeight = dom.innerHeight || Math.min(dom.clientHeight,dom.clientHeight);
       if(clientHeight + scrollTop+100 >= scrollHeight){
         if(this.page >= this.pageSize ) return
         this.page++
         this.getHomeworkList()
       }
    },
    getHomeworkList() {
      // 异步更新数据
      const data = {
        page: this.page,
        page_nums: 10,
        class_id: this.classId
      }
      // https://test-school.tenghuiketang.com ${getHost()}
      this.$tenghuiApi.post(`${getHost()}/studentPersonalCenter/getMobileWatchDataInfo`, data).then(res => {
        if (res && res.data.code == 200) {
          this.pageSize = (res.data.data.total > 10? Math.ceil(res.data.data.total/10):1)
          this.homeworkList = [...this.homeworkList, ...res.data.data.list]
          this.homeworkList.forEach((item, index) => {
            item.id = `${index + 1}`
          })
        }
      }).catch((err) => {
        // this.$message.error(err.message);
      });
    }
  }
}
</script>

<style scoped>
.view-info {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
}
.view-main {
  margin-top: 12px;
  height: calc(100% - 60px);
  overflow-y: auto;
}
.view-main ::v-deep .van-collapse .van-collapse-item__content {
  background: #f6f6f6 !important;
}
</style>