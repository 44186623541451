<template>
  <div class="appclass">
    <div v-if="insertmoblie != 1" class="titlist">
      <van-tabs v-model="activeStudy">
        <van-tab title="我的学习"></van-tab>
        <van-tab title="1v1约课"></van-tab>
      </van-tabs>
    </div>
    <!-- 我的学习 -->
    <div class="meal" v-show="!activeStudy">
      <van-tabs v-model="activeName" @click="handleClick">
        <van-tab
          title="全部课程"
          name=""
          style="background-color: #f5f5f5; overflow: hidden"
        >
        </van-tab>
        <van-tab
          title="vip课程"
          name="2"
          style="background-color: #f5f5f5; overflow: hidden"
        >
          <!-- <van-list
                v-model="loadinger"
                :finished="finisheder"
                finished-text="没有更多了"
                @load="myCourseser"
              >
              <div class="course">
                <div class="quanbukechena">
                  <div v-for="item in listaer" :key="item.id" class="onelisr">
                    <div class="one">
                      <div class="img-box">
                        <img class="tuimg" :src="item.cover" alt="" />
                        <div class="con-audio" @click="handleTolivingPage(item)" v-if="item.chapterStatus == 2">
                          <section>
                            <span></span>
                            <span></span>
                            <span></span>
                          </section>
                        </div>
                        <div class="mask" v-if="item.chapterStatus == 2"></div>
                      </div>
                      <p class="shujuzhibiao">{{item.name}}</p>
                      <p class="dijiqi">{{item.period}}</p>
                      <p class="kaikeshijian">开课：{{item.start_time}}</p>
                      <span class="f" v-if="item.endNum <= 0">查看回放</span>
                      <router-link :to="{ path: 'zhibo', query: { setid: item.course_id,class_id:item.class_id } }">
                        <div v-if="item.endNum <= 0" class="f_d"><a href="#" style="color: #fff;">查看回放</a></div>
                        <div v-else-if="item.endNum >= 1"><a href="#">查看回放</a></div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              </van-list> -->
        </van-tab>
        <van-tab
          title="免费课程"
          name="1"
          style="background-color: #f5f5f5; overflow: hidden"
        >
          <!-- <van-list
                v-model="loadingyi"
                :finished="finishedyi"
                finished-text="没有更多了"
                @load="myCoursesyi"
              >
              <div class="course">
                <div class="quanbukechena">
                  <div v-for="item in listayi" :key="item.id" class="onelisr">
                    <div class="one">
                      <div class="img-box">
                        <img class="tuimg" :src="item.cover" alt="" />
                        <div class="con-audio" @click="handleTolivingPage(item)" v-if="item.chapterStatus == 2">
                          <section>
                            <span></span>
                            <span></span>
                            <span></span>
                          </section>
                        </div>
                        <div class="mask" v-if="item.chapterStatus == 2"></div>
                      </div>
                      <p class="shujuzhibiao">{{item.name}}</p>
                      <p class="dijiqi">{{item.period}}</p>
                      <p class="kaikeshijian">开课：{{item.start_time}}</p>
                      <span class="f" v-if="item.endNum <= 0">查看回放</span>
                      <router-link :to="{ path: 'zhibo', query: { setid: item.course_id,class_id:item.class_id} }">
                        <div v-if="item.endNum <= 0" class="f_d"><a href="#" style="color: #fff;">查看回放</a></div>
                        <div v-else-if="item.endNum >= 1"><a href="#">查看回放</a></div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              </van-list> -->
        </van-tab>
        <van-tab
          title="历史观看"
          name="3"
          style="background-color: #f5f5f5; overflow: hidden"
        >
        </van-tab>
      </van-tabs>
      <!-- <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="myCourses"
              > -->
      <div class="course" v-if="activeName != 3">
        <div class="quanbukechena">
          <div v-for="item in lista" :key="item.id" class="one">
            <div class="img-box">
              <img class="tuimg" :src="item.cover" alt="" />
              <div
                class="con-audio"
                @click="handleTolivingPage(item)"
                v-if="item.chapterStatus == 2&&showLivingIcon"
              >
                <section>
                  <span></span>
                  <span></span>
                  <span></span>
                </section>
              </div>
              <div class="mask" v-if="item.chapterStatus == 2&&showLivingIcon"></div>
            </div>
            <p class="shujuzhibiao">{{ item.name }}</p>
            <p class="dijiqi">{{ item.period }}</p>
            <p class="kaikeshijian">开课：{{ item.start_time }}</p>
            <span class="f" v-if="item.endNum <= 0">开始学习</span>
            <router-link
              :to="{
                path: 'zhibo',
                query: { setid: item.course_id, class_id: item.class_id },
              }"
            >
              <div v-if="item.endNum <= 0" class="f_d">
                <a href="#" style="color: #1872ed">开始学习</a>
              </div>
              <div v-else-if="item.endNum >= 1"><a href="#">开始学习</a></div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- 历史观看 -->
      <div class="course" v-else>
        <div
          class="quanbukechena"
          style="padding-top: 10px; padding-bottom: 1px"
        >
          <div
            v-for="item in historyList"
            :key="item.id"
            class="coursebx"
            @click="tostudy(item)"
          >
            <div class="">
              <img class="tuimgs" :src="item.img" alt="" />
            </div>
            <div class="centon">
              <p class="shujuzhibiaos">{{ item.name }}</p>
              <p class="ylook">已观看到 {{ transformation(item.view_time) }}</p>
              <div class="progressbx">
                <van-progress
                  :percentage="item.percent"
                  :show-pivot="false"
                /><span class="bfb">{{ item.percent }}%</span>
              </div>
            </div>
            <!-- <p class="dijiqi">{{item.period}}</p> -->
            <!-- <p class="kaikeshijian">开课：{{item.start_time}}</p>
                    <span class="f" v-if="item.endNum <= 0">开始学习</span>
                    <router-link :to="{ path: 'zhibo', query: { setid: item.course_id,class_id:item.class_id} }">
                      <div v-if="item.endNum <= 0" class="f_d"><a href="#" style="color: #fff;">开始学习</a></div>
                      <div v-else-if="item.endNum >= 1"><a href="#">开始学习</a></div>
                    </router-link> -->
          </div>
        </div>
      </div>
      <!-- </van-list> -->
    </div>
    <!-- 1v1约课 -->
    <div class="meal" v-show="activeStudy">
      <div class="course">
        <div class="quanbukechena" style="margin-top: 0.27rem">
          <div v-for="item in Course1v1" :key="item.id" class="one">
            <div class="img-box">
              <img class="tuimg" :src="item.cover" alt="" />
              <div
                class="con-audio"
                @click="handleTolivingPage(item)"
                v-if="item.chapterStatus == 2"
              >
                <section>
                  <span></span>
                  <span></span>
                  <span></span>
                </section>
              </div>
              <div class="mask" v-if="item.chapterStatus == 2"></div>
            </div>
            <p class="shujuzhibiao">{{ item.name }}</p>
            <p class="dijiqi">{{ item.period }}</p>
            <p class="kaikeshijian" style="margin-bottom: 20px">
              开课：{{ item.start_time }}
            </p>
            <router-link
              :to="{
                path: '1v1',
                query: { setid: item.course_id, class_id: item.class_id },
              }"
            >
              <div><a href="#">开始学习</a></div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <div v-show="insertmoblie != 1" style="margin-top: 48px; z-index: 99">
      <van-tabbar
        :fixed="true"
        active-color="#1872ed"
        v-model="active"
        style="z-index: 10"
      >
        <van-tabbar-item :to="{ path: '/' }">
          <span>首页</span>
          <template #icon="props">
            <img :src="props.active ? home.active : home.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item :to="{ path: 'myStudy', query: { setid: 123123 } }">
          <span>学习</span>
          <template #icon="props">
            <img :src="props.active ? study.active : study.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item :to="{ path: 'my', query: { setid: 123123 } }" v-if="unreadCount!=0" :badge="unreadCount">
          <span>我的</span>
          <template #icon="props">
            <img :src="props.active ? my.active : my.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item :to="{ path: 'my', query: { setid: 123123 } }" v-else>
          <span>我的</span>
          <template #icon="props">
            <img :src="props.active ? my.active : my.inactive" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import { Toast, Popup, List, Loading } from "vant";
import Vue from "vue";
import { mapGetters } from "vuex";
Vue.use(Popup);
Vue.use(Toast);
Vue.use(List);
Vue.use(Loading);
export default {
  name: "HelloWorld",
  data() {
    return {
      loading: false,
      finished: false,
      lista: [],
      historyList: [],
      loadingyi: false,
      finishedyi: false,
      listayi: [],
      loadinger: false,
      finisheder: false,
      listaer: [],
      activeStudy: 0,
      Course1v1: [],
      item: 1,
      page: 1,
      pageyi: 1,
      pageer: 1,
      active: 1,
      activeName: "",
      insertmoblie: false,
      type: "",
      wx_h5_url: "https://u.shkt.online",
      my: {
        active: require("../../assets/images/my-active.png"),
        inactive: require("../../assets/images/my.png"),
      },
      study: {
        active: require("../../assets/images/study-active.png"),
        inactive: require("../../assets/images/study.png"),
      },
      home: {
        active: require("../../assets/images/home-active.png"),
        inactive: require("../../assets/images/home.png"),
      },
      showLivingIcon:false,
    };
  },
  computed: {
    ...mapGetters({
      org_id: "user/getOrgId",
      fans_id: "user/getFansId",
      unreadCount:'user/getUnreadCount'
    }),
  },
  created() {
    this.getconfig()
    console.log("this.$router.query", this.$route.query);
    this.insertmoblie = this.$route.query.insertmoblie;
    this.isupdate = this.$route.query.isupdate || 0;
    if (this.$route.query.moblie) {
      this.moblieType = this.$route.query.moblie;
    }
    if (this.$route.query.insertmoblie == 1) {
      console.log("嵌入在手机页面里面");
      localStorage.setItem("token", this.$route.query.token);
    }
    this.myCourses();
    this.my1v1CourseList();
    this.getPlayerH5URL();
    this.getCourseHomeworkRead()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path == "/1v1") {
        vm.$nextTick(() => {
          vm.activeStudy = 1;
        });
      }
    });
  },
  methods: {
     async getCourseHomeworkRead(){
                const res=await this.$tenghuiApi.post('/onlineapi/user/index/getCourseRead')
                console.log('[<-- res作业未读数 -->]',res);
                if (res.data.code==200) {
                    this.$store.commit("user/setUnreadCount", res.data.data);
                    localStorage.setItem('unreadCount',res.data.data)
                } 
            },
     async getconfig(){
      const res = await  this.$api.post('/user/index/config')
      const { data } = res
      if (data.code === 200) {   
        let configValue=data.data.filter(e=>e.key=='student_only_access')
        if (configValue.length > 0) {
          this.showLivingIcon=configValue[0].value=='true'?false:true
        }  
      }
    },
    transformation(duration) {
      let g = duration;
      let m = parseInt((duration / 60) % 60);
      let s = duration % 60;
      if (s <= 9) {
        s = "0" + s;
      }
      if (m <= 9) {
        m = "0" + m;
      }
      if (g >= 3600) {
        g = g / 3600;
        g = parseInt(g);
        g = "0" + g + ":";
      } else {
        g = "";
      }
      return g + m + ":" + s;
    },
    async historyinit() {
      const data = {
        page: this.page,
        page_num: 10000,
      };
      const res = await this.$api.post("/user/video/history", data);
      console.log(res.data.data.data, "this.pageSize");
      this.pageSize =
        res.data.data.count > 10 ? Math.ceil(res.data.data.count / 10) : 1;
      this.historyList = [...res.data.data.data];
    },
    handleClick(tab, event) {
      this.type = this.activeName;
      if (tab == 3) {
        this.historyinit();
      } else {
        this.myCourses();
      }
      console.log(tab, event);
    },
    //1v1课程
    my1v1CourseList() {
      const data = {
        page: this.page,
        num: this.num,
      };
      this.$api.post("/stu/course/my1V1Courses", data).then((res) => {
        if (res.data.code === 200) {
          this.Course1v1 = res.data.data.list;
          console.log(this.Course1v1);
        }
      });
    },
    myCourses() {
      // 课程详情
      const data = {
        page: this.page,
        num: 10000,
        type: this.type,
      };
      this.$api.post("/stu/course/myCourses", data).then((res) => {
        if (res.data.code === 200) {
          // 加载状态结束
          this.lista = res.data.data.list;
          // this.loading = false;
          // if (res.data.data.list < 10) {
          //    this.finished = true;
          // }
          // this.page = this.page + 1
          // this.lista = this.lista.concat(res.data.data.list)
        } else {
          this.loading = false;
        }
      });
    },
    tostudy(item) {
      console.log(item);
      this.$router.push({
        path: "/zhibo",
        query: {
          setid: item.course_id,
          class_id: item.class_id,
          view_time: item.view_time,
          video_id: item.video_id,
          chapter_id: item.chapter_id,
          sectionId: item.sectionId,
          file_id: item.file_id,
          name: item.name,
          // sectionId:438
        },
      });
    },
    // myCoursesyi () {
    //   // 课程详情
    //   const data = {
    //     page: this.pageyi,
    //     num: 10,
    //     type: 1
    //   }
    //    this.$api.post('/stu/course/myCourses', data)
    //     .then(res => {
    //       if (res.data.code === 200) {
    //         // 加载状态结束
    //         this.loadingyi = false;
    //         if (res.data.data.list < 10) {
    //            this.finishedyi = true;
    //         }
    //         this.pageyi = this.pageyi + 1
    //         this.listayi = this.listayi.concat(res.data.data.list)
    //       }else {
    //           this.loadingyi = false
    //       }
    //     })
    // },
    // myCourseser () {
    //   // 课程详情
    //   const data = {
    //     page: this.pageer,
    //     num: 10,
    //     type: 2
    //   }
    //    this.$api.post('/stu/course/myCourses', data)
    //     .then(res => {
    //       if (res.data.code === 200) {
    //         // 加载状态结束
    //         this.loadinger = false;
    //         if (res.data.data.list < 10) {
    //            this.finisheder = true;
    //         }
    //         this.pageer = this.pageer + 1
    //         this.listaer = this.listaer.concat(res.data.data.list)
    //       }else {
    //           this.loadinger = false
    //       }
    //     })
    // },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        // console.log('1111111111555555555555#########', this.lista.length)
        let xiaotan = this.lista.length;
        for (let i = 0; i < 10; i++) {
          this.lista.push(this.lista.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成

        if (xiaotan >= this.lista.length) {
          console.log("1111111111555555555555#########", xiaotan);
          this.finished = true;
        }
      }, 1000);
    },
    async getPlayerH5URL() {
      try {
        let data = await this.$api.get(
          "https://shanhu-live-user-1317978474.cos.accelerate.myqcloud.com/web/config/miniConfig.json?a=" +
            new Date().valueOf()
        );
        if (data && data.data && data.data.wx_h5_url) {
          this.wx_h5_url = data.data.wx_h5_url;
        }
      } catch (error) {
        console.error(error);
      }

      try {
        let data2 = await this.$api.get(
          "https://tengfaceliveuser.tengface.net/web/config/userConfig/" +
            this.org_id +
            ".json?a=" +
            new Date().valueOf()
        );
        if (data2 && data2.data && data2.data.wx_h5_url) {
          this.wx_h5_url = data2.data.wx_h5_url;
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleTolivingPage(item) {
      // 直播鉴权
      const data = {
        course_id: item.course_id,
        class_id: item.class_id,
        chapter_id: item.chapter_id,
      };

      this.$api.post("/stu/course/liveAuth", data).then((res) => {
        if (res.data.code === 200) {
          if (item.interact_id) {
            location.href = `${this.wx_h5_url}/tcicMiddlePage/#/h5?classid=${item.interact_id}&fans_id=${this.fans_id}`;
          } else if (item.live_url) {
            const { liveToken } = res.data.data;
            const url = `${this.wx_h5_url}/?ds_id=${
              item.ds_id
            }&token=${liveToken}&other=1&shool_token=${localStorage.getItem(
              "token"
            )}`;
            location.href = url;
          }
        } else {
          Toast.fail(res.data.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.appclass >>> .van-tab__pane,
.van-tab__pane-wrapper {
  padding-bottom: 170px;
}

.meal >>> .van-tabs__line {
  background: linear-gradient(98deg, #73aaff 0%, #0053ff 100%);
  width: 0.27rem;
  bottom: 20px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.all {
  position: relative;
}

.course .one .img-box {
  width: 3.2rem;
  height: 2.35rem;
  float: left;
  margin-top: 0.43rem;
  margin-left: 0.16rem;
  position: relative;
  border: none;
}

.course .one .mask {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 9px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.course .one .img-box .con-audio {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: backColor 4s infinite;
  border-radius: 0%;
  width: 2rem;
  height: 0.5rem;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  z-index: 2;
  /* overflow: hidden; */
}

.course .one .img-box .con-audio section {
  width: max-content;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.course .one .img-box .con-audio span:nth-child(1) {
  width: 0.1rem;
  height: 0.36rem;
  /* margin-left: 3px; */
  border-radius: 0.08rem;
  display: inline-block;
  position: relative;
  background: #4abb96;
  color: #4abb96;
  animation: loading 0.4s 0.3s linear infinite alternate;
  animation-play-state: running;
  margin: 0;
}

.course .one .img-box .con-audio span:nth-child(2) {
  margin: 0;
  width: 0.1rem;
  height: 0.36rem;
  margin-left: 0.08rem;
  border-radius: 0.08rem;
  display: inline-block;
  position: relative;
  background: #4abb96;
  color: #4abb96;
  animation: loading 0.5s 0.5s linear infinite alternate;
  animation-play-state: running;
}

.course .one .img-box .con-audio span:nth-child(3) {
  margin: 0;
  width: 0.1rem;
  height: 0.36rem;
  margin-left: 0.08rem;
  border-radius: 0.08rem;
  display: inline-block;
  position: relative;
  background: #4abb96;
  color: #4abb96;
  animation: loading 0.6s 0.8s linear infinite alternate;
  animation-play-state: running;
}

@keyframes loading {
  0% {
    height: 18px;
    transform: translateY(0);
  }

  100% {
    height: 5px;
    transform: translateY(7.5px);
  }
}

.tuimg {
  width: 3.2rem;
  height: 2.35rem;
}

.titlist {
  width: 100%;
  height: 1.17rem;
  background-color: #ffffff;
  overflow: hidden;
}

.titlist h1 {
  height: 24px;
  font-size: 17px;
  font-weight: 600;
  color: #101010;
  line-height: 24px;
  text-align: center;
  margin-top: 0.24rem;
  margin-bottom: 0.29rem;
}

.shujuzhibiaos {
  /* word-break: keep-all;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.4rem;
  font-weight: 500;
}

.meal {
  width: 100%;
  background: #f5f5f5;
}

.meal ul li {
  float: left;
  list-style-type: none;
  padding: 14px 0;
}

.meal ul li a {
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #101010;
  line-height: 20px;
  padding: 0px 14px;
}

.course {
  /* width: 9.47rem; */
  /* background: #fff; */
  border-radius: 10px;
  overflow: hidden;
  margin-left: 0.27rem;
  margin-right: 0.27rem;
  margin-top: 0.27rem;
}

.quanbukechena {
  background-color: #fff;
  margin-bottom: 60px;
}

.course .one {
  /* height: 3.1rem; */
  padding: 10px 0;
  overflow: hidden;
  background-color: #fff;
  /* margin-bottom: 0.47rem; */
}

.coursebx {
  display: flex;
  margin-bottom: 0.625rem;
  margin-left: 0.16rem;
  align-items: center;
}

.course .one img {
  float: left;
  border-radius: 9px;
}

.course .one p {
  float: left;
  width: 4.91rem;
  margin-left: 0.32rem;
}

.course .one .f {
  margin-top: 0.11rem;
  margin-left: 0.32rem;
  float: left;
  font-size: 12px;
  font-weight: 400;
  color: #ff8500;
  line-height: 17px;
}

.course .one span {
  float: left;
  font-size: 12px;
  font-weight: 400;
  color: #10a675;
  line-height: 17px;
  margin-top: 0.21rem;
  margin-left: 0.32rem;
}

.progressbx {
  width: 100%;
  position: relative;
}

.course .one .f_d {
  float: right;
  width: 1.92rem;
  height: 0.64rem;
  border-radius: 16px;
  text-align: center;
  line-height: 0.64rem;
}

.course .one div {
  float: right;
  width: 1.92rem;
  height: 0.64rem;
  border: 1px solid #1872ed;
  border-radius: 16px;
  text-align: center;
  line-height: 0.64rem;
  margin-right: 0.48rem;
}

.tuimgs {
  width: 3.2rem;
  height: 2.5rem;
  border-radius: 0.3125rem;
}

.course .one div a {
  font-size: 12px;
  font-weight: 400;
  color: #1872ed;
}

.ylook {
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 0.32rem;
}

.bfb {
  position: absolute;
  top: -5px;
  right: 5px;
}

.centon {
  flex: 1;
  margin-left: 10px;
}

::v-deep .van-progress {
  width: 80%;
}

.one:last-child {
  /* margin-bottom: 0.43rem; */
  border-radius: 0px 0px 9px 9px;
}

.onelisr:last-child {
  /* margin-bottom: 0.43rem; */
  border-radius: 0px 0px 9px 9px;
}

.shujuzhibiao {
  margin-top: 0.43rem;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.43rem;
  font-weight: 600;
}

.dijiqi {
  font-weight: 400;
  color: #888888;
  font-size: 0.32rem;
  margin-top: 0.11rem;
}

.kaikeshijian {
  font-weight: 400;
  color: #888888;
  font-size: 0.32rem;
  margin-top: 0.11rem;
}
</style>
